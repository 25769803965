import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Stack,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";

import useFetch from "../../useFetch";
import { getTokenFromCookie } from "../../Utils/cookieUtils";

export default function MigratedStudent(props) {
  const [toggle, setToggle] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userData, setUserData] = useFetch("");

  const [imgUrl, setImgUrl] = useState("");

  const [jamaatUrl, setJamaatUrl] = useFetch("");
  const [jamaatId, setJamaatId] = useState("");
  const [list, setList] = useFetch({});

  const getToken = getTokenFromCookie();

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClick = (e) => {
    if (inputValue.trim() === "") {
      toast.error("ITS_ID Required!");
      return;
    }
    setUserData(
      `${process.env.REACT_APP_BASE_API}migrating_students/user/details/${inputValue}`
    );
    setImgUrl(`${process.env.REACT_APP_MUMIN_IMAGE_URL}${inputValue}.png`);

    if (!toggle) {
      setToggle(true);
    }
  };

  useEffect(() => {
    setJamaatUrl(
      `${process.env.REACT_APP_BASE_API}migrating_students/supervisor/userJamaatID`
    );
  }, []);

  useEffect(() => {
    if (jamaatUrl && jamaatUrl[0]) {
      setJamaatId(jamaatUrl[0].jamaat_id);
      if (jamaatId) {
        const userListUrl = `${process.env.REACT_APP_BASE_API}migrating_students/supervisor/userList/${jamaatId}`;
        setList(userListUrl);
      }
    }
  }, [jamaatUrl, jamaatId]);

  // Add New User Fetch Api
  const handlePostRequest = () => {
    const postData = {
      its_id: `${inputValue}`,
      role: "",
      jamaat_id: `${jamaatId}`,
      is_active: "",
    };

    const apiUrl = `${process.env.REACT_APP_BASE_API}migrating_students/supervisor/addUser`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${getToken}`,
      },
      body: JSON.stringify(postData),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Boolean(data.status)) {
          toast.success("New User Added", data);
        } else {
          toast.error(data.error);
        }
        const userListUrl = `${process.env.REACT_APP_BASE_API}migrating_students/supervisor/userList/${jamaatId}`;
        setList(userListUrl);
      })
      .catch((error) => {
        toast.error("There was a problem with the POST request:", error);
      });
  };
  // CheckBox Api Fetch
  const handlePostCheckRequest = async (itsId) => {
    try {
      const postData = {
        its_id: itsId,
        role: "",
        jamaat_id: `${jamaatId}`,
        is_active: "",
      };
      const apiUrl = `${process.env.REACT_APP_BASE_API}migrating_students/aamilsaheb/assignSupervisor`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${getToken}`,
        },
        body: JSON.stringify(postData),
      };

      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      toast.success("Made Supervisor", data);

      const userListUrl = `${process.env.REACT_APP_BASE_API}migrating_students/supervisor/userList/${jamaatId}`;
      setList(userListUrl);
    } catch (error) {
      toast.error("There was a problem with the POST request:", error);
    }
  };

  return (
    <>
      <Row
        style={{
          backgroundColor: "#E5E5E5",
          margin: "0px",
        }}
      >
        {/* Left Section */}
        <Col xs={12} lg={6} className="main-content mt-3">
          <div className="explaination-box d-none d-sm-block">
            <Form.Text className="text-info">
              <h5>Umoor Talimiyah Members</h5>
            </Form.Text>
            <hr className="underline" />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <InputGroup className="w-50 margin">
                <Form.Control
                  onChange={handleChange}
                  type="number"
                  placeholder="Enter ITS_ID"
                  aria-label="id"
                  aria-describedby="basic-addon1"
                />
                <Button variant="info" id="search-btn" onClick={handleClick}>
                  Fetch
                </Button>
              </InputGroup>
            </Form.Group>
            {toggle && userData ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imgUrl}
                    alt="user-avatar"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  />
                  <ul>
                    <li
                      style={{
                        fontWeight: "bold",
                        listStyle: "none",
                      }}
                    >
                      {userData?.full_name_en}
                    </li>
                    <li
                      style={{
                        fontWeight: "lighter",
                        listStyle: "none",
                      }}
                    >
                      {userData?.email}
                    </li>
                  </ul>
                </div>
                <Stack
                  direction="horizontal"
                  gap={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    as="a"
                    variant="success"
                    onClick={handlePostRequest}
                    style={{ margin: "10px" }}
                  >
                    Add user
                  </Button>
                </Stack>
              </Form.Group>
            ) : (
              ""
            )}
          </div>
        </Col>

        {/* Right Section */}
        <Col xs={12} lg={6} className="main-content mt-3">
          <div className="explaination-box d-none d-sm-block">
            <Form className="p-0">
              <>
                <div style={{ backgroundColor: "white" }}>
                  <Form.Text className="text-info">
                    <h5>Members List</h5>
                  </Form.Text>
                  <hr className="underline" />

                  {!list ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  ) : (
                    list.map((item, index) => (
                      <div key={index}>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_MUMIN_IMAGE_URL}${item.its_id}.png`}
                            alt="user-avatar"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              marginBottom: "3em",
                            }}
                          />
                          <ul>
                            <li
                              style={{
                                fontWeight: "light",
                                listStyle: "none",
                              }}
                            >
                              {item.name}{" "}
                            </li>
                            <li
                              style={{
                                fontWeight: "lighter",
                                listStyle: "none",
                              }}
                            >
                              {item.role}
                            </li>
                            <li
                              style={{
                                fontWeight: "lighter",
                                listStyle: "none",
                              }}
                            >
                              {item.its_id}
                            </li>
                            <hr className="underline"></hr>
                          </ul>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </>
            </Form>
          </div>
        </Col>
      </Row>
      <ToastContainer autoClose={1000} hideProgressBar={true} />
    </>
  );
}
